import {BasketItem, CampaignPack, Pack} from '../types';
import {CampaignDetails} from '../types/models/CampaignDetails';
import {CampaignOverview} from '../types/models/CampaignOverview';
import {addDays, getEndOfDay} from './date';
import {sorted} from './list';

export enum CampaignStatus {
    BEFORE = 'campaign.status.before',
    PENDING = 'campaign.status.pending',
    EXTRA = 'campaign.status.extra',
    AFTER = 'campaign.status.after',
}

export function getCampaignStatus(
    campaign: CampaignOverview | CampaignDetails | CampaignPack
) {
    if (!campaign) {
        return CampaignStatus.AFTER;
    }
    if (!campaign.start_datetime) {
        return CampaignStatus.BEFORE;
    }

    const now = new Date(Date.now());
    const start = new Date(campaign.start_datetime);
    const end = new Date(campaign.end_datetime);
    const prolongation = addDays(end, campaign.prolongation);

    // console.log('now = ', now);
    // console.log('start = ', start);
    // console.log('end = ', end);
    // console.log('prolongation = ', prolongation);

    if (now < start) {
        return CampaignStatus.BEFORE;
    } else if (now > start && now < end) {
        return CampaignStatus.PENDING;
    } else if (now > end && now < prolongation) {
        return CampaignStatus.EXTRA;
    } else {
        return CampaignStatus.AFTER;
    }
}

export function getClosingDay(campaign: CampaignOverview) {
    return new Date(campaign.end_datetime);
}

export enum TimeLeft {
    DAYS = 'campaign.time.day',
    HOURS = 'campaign.time.hour',
    MINUTES = 'campaign.time.minute',
    SECONDS = 'campaign.time.second',
    D = 'campaign.time.d',
    H = 'campaign.time.h',
    M = 'campaign.time.m',
    S = 'campaign.time.s',
}

export function getCampaignTimeLeft(campaign: CampaignOverview | CampaignDetails) {
    var second = 1000,
        minute = second * 60,
        hour = minute * 60,
        day = hour * 24;
    const status = getCampaignStatus(campaign);
    // console.log('status = ', status);

    if (status == CampaignStatus.BEFORE || status == CampaignStatus.AFTER) {
        return {
            type: TimeLeft.SECONDS,
            amount: 0,
        };
    }

    let end = new Date(campaign.end_datetime);
    if (status == CampaignStatus.EXTRA) {
        end = addDays(end, campaign.prolongation);
    }

    const now = new Date(Date.now());
    const diff = end.getTime() - now.getTime();
    const daysLeft = Math.floor(diff / day);
    // console.log('days left = ', daysLeft);
    if (daysLeft == 0) {
        const hoursLeft = Math.floor(diff / hour);
        // console.log('hoursLeft left = ', hoursLeft);
        if (hoursLeft == 0) {
            const minutesLeft = Math.floor(diff / minute);
            // console.log('minutesLeft left = ', minutesLeft);
            if (minutesLeft == 0) {
                const secondsLeft = Math.floor(diff / second);
                // console.log('secondsLeft left = ', secondsLeft);
                return {
                    type: TimeLeft.SECONDS,
                    short: TimeLeft.S,
                    amount: secondsLeft,
                };
            } else {
                return {
                    type: TimeLeft.MINUTES,
                    short: TimeLeft.M,
                    amount: minutesLeft,
                };
            }
        } else {
            return {
                type: TimeLeft.HOURS,
                short: TimeLeft.H,
                amount: hoursLeft,
            };
        }
    } else {
        return {
            type: TimeLeft.DAYS,
            short: TimeLeft.D,
            amount: daysLeft,
        };
    }
}

export function getTempPackTimeLeft(pack: Pack) {
    var second = 1000,
        minute = second * 60,
        hour = minute * 60,
        day = hour * 24;

    const now = new Date(Date.now());
    const end = getEndOfDay(pack.end_datetime);

    // console.log('now = ', now);
    // console.log('end = ', end);

    let diff = end.getTime() - now.getTime();
    // console.log('diff = ', diff);

    const daysLeft = Math.floor(diff / day);
    // console.log('days left = ', daysLeft);
    if (daysLeft == 0) {
        const hoursLeft = Math.floor(diff / hour);
        // console.log('hoursLeft left = ', hoursLeft);
        if (hoursLeft == 0) {
            const minutesLeft = Math.floor(diff / minute);
            // console.log('minutesLeft left = ', minutesLeft);
            if (minutesLeft == 0) {
                const secondsLeft = Math.floor(diff / second);
                // console.log('secondsLeft left = ', secondsLeft);
                return {
                    type: TimeLeft.SECONDS,
                    amount: secondsLeft,
                };
            } else {
                return {
                    type: TimeLeft.MINUTES,
                    amount: minutesLeft,
                };
            }
        } else {
            return {
                type: TimeLeft.HOURS,
                amount: hoursLeft,
            };
        }
    } else {
        return {
            type: TimeLeft.DAYS,
            amount: daysLeft,
        };
    }
}

export function getCampaignHome(campaigns: CampaignOverview[]) {
    if (!campaigns) {
        return null;
    }
    if (campaigns.length == 0) {
        return null;
    }

    let current = null;

    for (const campaign of campaigns) {
        const status = getCampaignStatus(campaign);
        if (status == CampaignStatus.PENDING || status == CampaignStatus.EXTRA) {
            current = campaign;
        }
    }

    return current;
}

function getStartTime(c: CampaignOverview) {
    return new Date(Date.parse(c.start_datetime)).getTime();
}

export function getCampaignsHome(campaigns: CampaignOverview[]) {
    if (!campaigns) {
        return [] as CampaignOverview[];
    }
    if (campaigns.length == 0) {
        return [] as CampaignOverview[];
    }

    let currents = [] as CampaignOverview[];

    for (const campaign of campaigns) {
        const status = getCampaignStatus(campaign);
        if (status == CampaignStatus.PENDING || status == CampaignStatus.EXTRA) {
            currents.push(campaign);
        }
    }

    const sortedCampaigns = sorted(currents, getStartTime);

    return sortedCampaigns;
}

export function getPastCampaigns(campaigns: CampaignOverview[] | null | undefined) {
    try {
        const currents = campaigns?.filter(isPastCampaign) ?? [];
        return sorted(currents, getStartTime);
    } catch (e) {
        console.log('error getPastCampaigns', e);
        return [];
    }
}

function isPastCampaign(campaign: CampaignOverview) {
    const status = getCampaignStatus(campaign);
    return status == CampaignStatus.AFTER;
}

export function isBasketEmpty(
    items: BasketItem[] | null | undefined,
    campaign: CampaignDetails | CampaignPack | null | undefined
) {
    if (!items) {
        return true;
    }
    if (!campaign) {
        return true;
    }

    let empty = true;
    for (const item of items) {
        for (const p of campaign.packs) {
            if (item.pack_id == p.pack_id) {
                empty = false;
            }
        }
    }

    return empty;
}
